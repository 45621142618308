export default {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 6,
  },
  variants: {
    none: {

    },
    default: {
      bg: 'offwhite',
      textAlign: 'center',
      w: { base: '100%', sm: '425px', xl: '425px' },
      borderRadius: '24px',
      overflow: 'hidden',
      shadow: 'big',
      h: '100%',
      '& > img.card-hero-img': {
        h: '250px',
        w: '100%',
        objectFit: 'cover',
      },
      '& > .card-content': {
        h: '100%',
      },
    },
    flag: {
      textAlign: 'left',
      flexDirection: { base: 'column', md: 'row' },
      position: 'relative',
      minHeight: '300px',
      w: { base: '75vw', md: 'auto' },
      h: '100%',
      '& > img.card-hero-img': {
        h: '300px',
        w: '300px',
        objectFit: 'cover',
        borderRadius: '300px',
        shadow: 'big',
        position: 'absolute',
      },
      '& p': {
        maxWidth: '50ch',
      },
      '& > .card-content': {
        h: '100%',
        w: '100%',
        minHeight: { md: '320px' },
        marginLeft: { md: '150px' },
        marginTop: { base: '175px', md: '0' },
        paddingLeft: { base: '40px', md: '175px' },
        paddingRight: { base: '40px', md: '0' },
        paddingTop: { base: '150px', md: '0' },
        paddingBottom: { base: '40px', md: '0' },
        shadow: 'flag',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'column',
        bg: 'white',
      },
      '& > .card-content a': {
        color: 'teal',
      }
    },
    mini: {
      bg: 'cobaltblue',
      w: '100%',
      h: '100%',
      color: 'white',
      padding: '1.5rem',
      borderRadius: '18px',
      '& > .card-content': {
        height: '100%',
      },
      '& img': {
        maxWidth: '160px',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
