import { createBreakpoints } from '@chakra-ui/theme-tools';

export default createBreakpoints({
  sm: '30em',
  md: '48em',
  ml: '60em',
  lg: '65em',
  xl: '80em',
  '2xl': '96em',
});
