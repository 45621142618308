export default {
  baseStyle: {
    transitionProperty: 'common',
    transitionDuration: 'fast',
    transitionTimingFunction: 'ease-out',
    cursor: 'pointer',
    outline: 'none',
    color: 'darkblue',
    textDecoration: 'underline',
    fontWeight: '700',
    _hover: {
      color: 'teal',
    },
  },
  variants: {
    'nav-link': {
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      letterSpacing: '.0625rem',
      fontWeight: '400',
      '.nav-link-active&': {
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
      },
    },
    unstyled: {
      color: 'inherit',
      textDecoration: 'inherit',
      fontWeight: 'inherit',
      _hover: {
        color: 'inherit',
      },
    },
  },
  defaultProps: {
  },
};
