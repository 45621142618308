import { extendTheme } from '@chakra-ui/react';

import global from './global';

import breakpoints from './breakpoints';
import colors from './colors';
import typography from './typography';
import shadows from './shadows';

import Button from './components/Button';
import Card from './components/Card';
import Container from './components/Container';
import Heading from './components/Heading';
import Text from './components/Text';
import Link from './components/Link';
import Tabs from './components/Tabs';
import Progress from './components/Progress';
import Input from './components/Input';
import Textarea from './components/Textarea';
import Accordion from './components/Accordion';
import Select from './components/Select';

export const themeAdditions = {
  styles: {
    global,
  },
  components: {
    Tabs,
    Progress,
    Input,
    Accordion,
    Textarea,
    Select,
  },
};

export const foundationOverrides = {
  breakpoints,
  colors,
  ...typography,
  shadows,
};

export const componentOverrides = {
  Button,
  Card,
  Container,
  Heading,
  Text,
  Link,
};

const extended = extendTheme(themeAdditions);

export default {
  ...extended,
  ...foundationOverrides,
  components: {
    ...extended.components,
    ...componentOverrides,
  },
};
