const h1 = {
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: 1.2,
  fontWeight: '700',
};

const h1plus = {
  fontSize: { base: 'xl', md: '3.75rem' },
  lineHeight: 1.2,
  fontWeight: '700',
}

const h2 = {
  fontSize: { base: 'lg', md: 'xl' },
  lineHeight: 1.2,
  fontWeight: '700',
  maxWidth: '30ch',
  mb: {
    base: '.75rem',
    md: '1.5rem',
  },
};

const h3 = {
  fontSize: { base: 'lg', md: 'xl' },
  lineHeight: 1.2,
  fontWeight: '700',
  maxWidth: '35ch',
  mb: {
    base: '.75rem',
    md: '1.5rem',
  },
};

const h4 = {
  fontFamily: 'body',
  fontSize: 'ml',
  fontWeight: '700',
  mb: {
    base: '.25rem',
    md: '.75rem',
  },
};

const h5 = {
  fontSize: 'ml',
  fontWeight: '400',
  mb: {
    base: '.25rem',
    md: '.75rem',
  },
};

const xxl = {
  fontSize: { base: 'xl', md: '2xl'},
  fontWeight: '900',
  lineHeight: 1,
};

const mega = {
  fontSize: { base: '2xl', md: '3xl' },
  fontWeight: '900',
  lineHeight: 1,
};

export default {
  baseStyle: {
    fontFamily: 'heading',
    color: 'cobaltblue',
  },
  sizes: {
    h1,
    xl: h1,
    h1plus,
    h2,
    lg: h2,
    h3,
    sub: h3,
    h4,
    h5,
    xxl,
    mega,
  },
};
