const maxW = 80;
const unit = 'rem';

/*
For reference from ../breakpoints.js
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
*/

const px = {
  container: {
    base: 1.25,
    md: 1.875,
    lg: 5.75,
    xl: 7.5,
  },
  callout: {
    base: 1,
    md: 1,
    lg: 2,
    xl: 2,
  },
};

const expand = (ob, modify) => Object.entries(ob).reduce((all, [key, value]) => ({
  ...all,
  [key]: modify(value),
}), {});

export default {
  baseStyle: {
    w: '100%',
    mx: 'auto',
    maxW: maxW + unit,
    px: expand(px.container, val => val + unit),
    py: {
      base: '1.25rem',
      md: '2.5rem',
    },
    mb: {
      base: '.5rem',
      md: '1.75rem',
    },
    '&:last-of-type': {
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
  variants: {
    'full-width': {
      maxW: '100%',
      px: 0,
    },
    callout: {
      w: expand(px.container, val => `calc(100% - ${val * 2}${unit})`),
      maxW: expand(px.container, val => maxW - val * 2 + unit),
      px: expand(px.callout, val => val + unit),
      borderRadius: '24px',
      shadow: 'big',
      mt: {
        base: '1rem',
        md: '7rem',
      },
      mb: {
        base: '1.5rem',
        md: '4rem',
      },
    },
    bar: {
      w: expand(px.container, val => `calc(100% - ${val * 2}${unit})`),
      maxW: expand(px.container, val => maxW - val * 2 + unit),
      px: expand(px.callout, val => val + unit),
      py: {
        base: '1.25rem',
        md: '1.25rem',
        lg: '2.5rem',
      },
      borderRadius: {
        base: '24px',
        lg: '100px',
      },
    },
    hero: {
      display: 'flex',
      alignItems: {
        base: 'flex-start',
        md: 'center',
        lg: 'center',
      },
      maxW: '100%',
      px: 0,
      pt: '5.3125rem',
      mb: 0,
    },
    'no-hero': {
      pt: {
        base: '6rem',
        md: '10rem',
      },
      pb: {
        base: 0,
        md: 0,
      },
      mt: {
        base: 0,
        md: 0,
      },
      mb: {
        base: '1.5rem',
        md: '3rem',
      },
    },
    panel: {
      position: 'relative',
      zIndex: '1',
      py: {
        base: '1.25rem',
        md: '4rem',
      },
      mt: {
        base: '1rem',
        md: '7rem',
      },
      mb: {
        base: '1.5rem',
        md: '4rem',
      },
      overflow: {
        base: 'hidden',
        lg: 'visible',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        display: {
          base: 'none',
          md: 'block',
        },
        h: '100%',
        zIndex: -1,
        top: 0,
        left: '-100%',
        width: '100%',
        bg: 'inherit',
        pointerEvents: 'none',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        display: {
          base: 'none',
          md: 'block',
        },
        h: '100%',
        zIndex: -1,
        top: 0,
        right: '-100%',
        width: '100%',
        bg: 'inherit',
        pointerEvents: 'none',
      },
    },
  },
};
