export default {
  fonts: {
    heading: 'Playfair Display, serif',
    body: 'Raleway, sans-serif',
  },
  fontSizes: {
    xxs: '.6875rem',
    xs: '.75rem',
    sm: '.875rem',
    md: '1rem',
    ml: '1.125rem',
    lg: '1.75rem',
    xl: '2.25rem',
    '2xl': '3.25rem',
    '3xl': '6.25rem',
  },
};
