import React from 'react';
import { PrismicProvider } from '@prismicio/react';
import { Helmet } from 'react-helmet';

import '@fontsource/raleway';
import '@fontsource/raleway/700.css';
import '@fontsource/playfair-display';
import '@fontsource/playfair-display/700.css';
import '@fontsource/playfair-display/900.css';

import 'slick-carousel/slick/slick.css';
import './src/css/overrides.css';

import { internalLinkComponent, richTextComponents } from './prismicComponents';

/* eslint-disable */
export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    richTextComponents={richTextComponents}
    internalLinkComponent={internalLinkComponent}
  >
    {/* Head} */}
    <Helmet>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-P9XG25F');
        `}
      </script>
    </Helmet>
    {/*Body */}
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P9XG25F"
        height="0"
        width="0"
        style="display:none;visibility:hidden;"
      ></iframe>
    </noscript>
    <div id="new-root">{element}</div>
  </PrismicProvider>
);
