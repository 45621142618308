import linkStyles from './Link';

export default {
  baseStyle: {
    lineHeight: '1.6',
    borderRadius: '100px',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    bg: 'lightblue',
    ...linkStyles.variants['nav-link'],
    color: 'black',
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _hover: {
      color: 'initial',
      _disabled: {
        bg: 'initial',
      },
    },
  },
  variants: {
    'nav-link': {

    },
  },
  sizes: {
    lg: {
      h: '3rem',
      minW: '3rem',
      fontSize: 'lg',
      px: '1.75rem',
    },
    md: {
      h: '2.5rem',
      minW: '2.5rem',
      fontSize: 'md',
      px: '1.5rem',
    },
    sm: {
      h: '2rem',
      minW: '2rem',
      fontSize: 'sm',
      px: '.75rem',
    },
    xs: {
      h: '1.5rem',
      minW: '1.5rem',
      fontSize: 'xs',
      px: '.5rem',
    },
  },
  defaultProps: {
    variant: '',
    size: 'md',
  },
};
