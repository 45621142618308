import React from 'react';

import { Link as GatsbyLink } from 'gatsby';

import {
  Heading,
  Text,
  Code,
  OrderedList,
  UnorderedList,
  ListItem,
  Image,
  Link,
} from '@chakra-ui/react';

const componentKey = (index, type) => `element-${type}-${index + 1}`;

const richTextComponents = {
  span: ({ text, key, type }) => (
    <React.Fragment key={componentKey(key, type)}>{text}</React.Fragment>
  ),
  heading1: ({ children, key, type }) => (
    <Heading
      as="h1"
      size="h1"
      key={componentKey(key, type)}
    >{children}
    </Heading>
  ),
  heading2: ({ children, key, type }) => (
    <Heading
      as="h2"
      size="h2"
      key={componentKey(key, type)}
    >{children}
    </Heading>
  ),
  heading3: ({ children, key, type }) => (
    <Heading
      as="h3"
      size="h3"
      key={componentKey(key, type)}
    >{children}
    </Heading>
  ),
  heading4: ({ children, key, type }) => (
    <Heading
      as="h4"
      size="h4"
      key={componentKey(key, type)}
    >{children}
    </Heading>
  ),
  heading5: ({ children, key, type }) => (
    <Heading
      as="h5"
      size="h5"
      key={componentKey(key, type)}
    >{children}
    </Heading>
  ),
  heading6: ({ children, key, type }) => (
    <Heading
      as="h6"
      size="h6"
      key={componentKey(key, type)}
    >{children}
    </Heading>
  ),
  paragraph: ({ children, key, type }) => (
    <Text key={componentKey(key, type)}>{children}</Text>
  ),
  preformatted: ({ children, key, type }) => (
    <Code key={componentKey(key, type)}>{children}</Code>
  ),
  strong: ({ children, key, type }) => (
    <Text
      as="strong"
      key={componentKey(key, type)}
    >{children}
    </Text>
  ),
  em: ({ children, key, type }) => (
    <Text
      as="em"
      key={componentKey(key, type)}
    >{children}
    </Text>
  ),
  listItem: ({ children, key, type }) => (
    <ListItem key={componentKey(key, type)}>{children}</ListItem>
  ),
  oListItem: ({ children, key, type }) => (
    <ListItem key={componentKey(key, type)}>{children}</ListItem>
  ),
  list: ({ children, key, type }) => (
    <UnorderedList key={componentKey(key, type)}>{children}</UnorderedList>
  ),
  oList: ({ children, key, type }) => (
    <OrderedList key={componentKey(key, type)}>{children}</OrderedList>
  ),
  image: ({
    src, alt, key, type,
  }) => (
    <Image
      src={src}
      alt={alt}
      key={componentKey(key, type)}
    />
  ),
  label: ({ children, key, type }) => (
    <Text
      as="label"
      key={componentKey(key, type)}
    >{children}
    </Text>
  ),
};

const internalLinkComponent = ({ href, children, ...props }) => {
  const isExternal = href.includes('http');
  return (
    <Link
      as={GatsbyLink}
      to={href}
      isExternal={isExternal}
    >{children}
    </Link>
  );
};

export {
  richTextComponents,
  internalLinkComponent,
};
