exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-prismic-bright-healthcare-wellness-visits-url-jsx": () => import("./../../../src/pages/{PrismicBrightHealthcareWellnessVisits.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-bright-healthcare-wellness-visits-url-jsx" */),
  "component---src-pages-prismic-careers-url-jsx": () => import("./../../../src/pages/{PrismicCareers.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-careers-url-jsx" */),
  "component---src-pages-prismic-case-study-url-jsx": () => import("./../../../src/pages/{PrismicCaseStudy.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-case-study-url-jsx" */),
  "component---src-pages-prismic-contact-us-url-jsx": () => import("./../../../src/pages/{PrismicContactUs.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-contact-us-url-jsx" */),
  "component---src-pages-prismic-covid-19-url-jsx": () => import("./../../../src/pages/{PrismicCovid19.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-covid-19-url-jsx" */),
  "component---src-pages-prismic-faq-url-jsx": () => import("./../../../src/pages/{PrismicFaq.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-faq-url-jsx" */),
  "component---src-pages-prismic-field-providers-url-jsx": () => import("./../../../src/pages/{PrismicFieldProviders.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-field-providers-url-jsx" */),
  "component---src-pages-prismic-health-plans-url-jsx": () => import("./../../../src/pages/{PrismicHealthPlans.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-health-plans-url-jsx" */),
  "component---src-pages-prismic-healthcare-provider-url-jsx": () => import("./../../../src/pages/{PrismicHealthcareProvider.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-healthcare-provider-url-jsx" */),
  "component---src-pages-prismic-homepage-url-jsx": () => import("./../../../src/pages/{PrismicHomepage.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-homepage-url-jsx" */),
  "component---src-pages-prismic-how-it-works-url-jsx": () => import("./../../../src/pages/{PrismicHowItWorks.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-how-it-works-url-jsx" */),
  "component---src-pages-prismic-news-events-url-jsx": () => import("./../../../src/pages/{PrismicNewsEvents.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-news-events-url-jsx" */),
  "component---src-pages-prismic-who-we-are-url-jsx": () => import("./../../../src/pages/{PrismicWhoWeAre.url}.jsx" /* webpackChunkName: "component---src-pages-prismic-who-we-are-url-jsx" */),
  "component---src-templates-basic-page-jsx": () => import("./../../../src/templates/BasicPage.jsx" /* webpackChunkName: "component---src-templates-basic-page-jsx" */)
}

