export default {
  body: {
    bg: 'white',
    color: 'black',
    overflowX: 'hidden',
    '--popper-arrow-size': '24px',
  },
  'p + p': {
    mt: '.5rem',
  }
};
