export default {
  variants: {
    rounded: {
      field: {
        border: '1px solid',
        borderColor: 'cobaltblue',
        borderRadius: '100px',
        bg: 'inherit',
        fontSize: 'md',
        height: 10,
        paddingX: 4,
        _hover: {
          borderColor: 'cobaltblue',
        },
        _readOnly: {
          boxShadow: 'none !important',
          userSelect: 'all',
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed',
        },
        _invalid: {
          borderColor: 'red.500',
          boxShadow: '0 0 0 1px red',
        },
        _focus: {
        },
      },
    },
  },
  defaultProps: {
    variant: 'rounded',
    size: null,
  },
};
