export default {
  variants: {
    default: {
      filledTrack: {
        bg: 'lightblue',
      },
      track: {
        bg: 'fadedLightBlue',
        borderRadius: '2px',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
