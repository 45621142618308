import linkStyles from './Link';

export default {
  variants: {
    minimal: {
      tablist: {
        borderColor: 'inherit',
      },
      tab: {
        ...linkStyles.variants['nav-link'],
        borderBottom: '1px solid',
        borderColor: 'transparent',
        marginBottom: '-1px',
        color: 'bluegray',
        pb: '0',
        px: '0',
        mr: '1rem',
        ml: '1rem',
        fontWeight: '700',
        '&:lastOfType': {
          mr: '0',
        },
        '&:firstOfType': {
          ml: '0',
        },
        _selected: {
          color: 'white',
          borderColor: 'currentColor',
        },
        _active: {
          bg: 'transparent',
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed',
        },
      },
      tabpanel: {
        px: 0,
      },
    },
  },
  defaultProps: {
    variant: 'minimal',
  },
};
