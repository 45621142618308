import linkStyles from './Link';

export default {
  baseStyle: {
    maxWidth: '80ch',
  },
  variants: {
    'nav-link': {
      ...linkStyles.baseStyle,
      ...linkStyles.variants['nav-link'],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '.0625rem',
    },
    legal: {
      fontStyle: 'italic',
      fontSize: 'sm',
      textAlign: 'center',
      mx: 'auto',
    },
  },
};
