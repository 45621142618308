export default {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  offwhite: '#EAEAEA',
  teal: '#43C7E2',
  cobaltblue: '#163873',
  lavendar: '#635F99',
  bluegray: '#668098',
  darkblue: '#0B233F',
  green: '#00BDA2',
  lightblue: '#98E2F1',
  fadedLightBlue: '#98E2F155', // 30% opacity
  fadedDarkBlue: '#01081CDD',
  halfDarkBlue: '#0B233F80',
  fadedBlueGray: '#042B4FAA',
  red: {
    500: '#E53E3E', // Error formatting
  },
};
