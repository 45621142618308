export default {
  baseStyle: {
    container: {
      borderTop: 0,
      '&:last-of-type': {
        borderBottom: 0,
      },
    },
  },
  defaultProps: {
  },
};
